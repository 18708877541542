export default [{
  title: 'Menu_Assistant.Back To Dashboard',
  route: 'assistant-reviews',
  icon: 'HomeIcon',
  id: 'backToDashboard',
},
{
  title: 'Menu_Assistant.Analysis Channel',
  route: 'Assistant',
  icon: 'GlobeIcon',
  id: 'assistant',
},
{
  title: 'Menu_Assistant.Analysis Video',
  route: 'Analysis-video',
  icon: 'FilmIcon',
  id: 'Analysis-video',
},
{
  title: 'Menu_Assistant.Need To Check',
  route: 'need-to-check',
  icon: 'CheckSquareIcon',
  id: 'need-to-check',
},
//  {
//   title: 'Analytics',
//   route: 'analytics',
//   icon: 'PieChartIcon',
//   id: 'analytics'
// },

]